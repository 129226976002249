img {
    width: 600px;
    height: 800px;
    margin: 0 auto;
    display: block;

}

@media screen and (max-width: 600px) {
    img {
        width: 400px;
        height: 450px;
    }
    
}


@media screen and (min-width: 1200px) {
    img {
        width: 1000px;
        height: 1250px;
    }
    
}